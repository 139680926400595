import React from 'react';
import '../css/SidePanel.css';

const SidePanel = ({ onSelectPage, onLogout }) => (
  <div className="side-panel">
    <h3>Admin Panel</h3>
    <ul>
      <li onClick={() => onSelectPage('Manage Subjects')}>Manage Subjects</li>
      <li onClick={() => onSelectPage('Manage Subtopics')}>Manage Subtopics</li>
      <li onClick={() => onSelectPage('Manage Questions')}>Manage Questions</li>
      <li onClick={() => onSelectPage('Manage Coupons')}>Manage Coupons</li>
      <li onClick={() => onSelectPage('Edit Questions')}>Edit Questions</li>
      <li onClick={() => onSelectPage('Manage Daily MCQ')}>Manage Daily MCQ</li>
      {/* Add a logout button */}
      <li onClick={onLogout} className="logout-button">Logout</li>
    </ul>
  </div>
);

export default SidePanel;
