import MenuBar from './MenuBar'; // Import the shared MenuBar component
import TopBar from './TopBar';
import React from 'react';
import './css/OfferPage.css'; // Import the CSS file for OfferPage

const OfferPage = () => {
  return (
    <div className="offerpage-container">
            <TopBar /> {/* TopBar at the top */}
          <MenuBar />
      <h1 className="coming-soon">Coming Soon</h1>
    </div>
  );
};

export default OfferPage;
