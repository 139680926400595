

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/TopBar.css';

const TopBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for toggling menu
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear all localStorage and cookies (example)
    localStorage.clear();
    navigate('/login'); // Navigate to login page
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="topbar">
      {/* Toggle Button */}
      <button
        className="menu-toggle"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-label="Toggle menu"
      >
        ☰
      </button>

      {/* Menu Buttons */}
      <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
        <button onClick={() => handleNavigation('/leaderboard')}>Leaderboard</button>
        <button onClick={() => handleNavigation('/offers')}>Offers</button>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default TopBar;
