import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/LoginPage.css';
import config from '../../constants'; // Import config containing baseUrl

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // For loading state
  const [errorMessage, setErrorMessage] = useState(''); // For error messages
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/admin');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(''); // Clear previous error message

    const maxAttempts = 3; // Maximum number of retries
    let attempt = 0;
    let success = false;

    while (attempt < maxAttempts && !success) {
      attempt += 1;
      try {
        const response = await fetch(`${config.baseUrl}/adminuserlogin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        const data = await response.json();

        if (response.ok) {
          // Validate and store token
          if (data.token) {
            localStorage.setItem('token', data.token);
            navigate('/admin');
            success = true;
          } else {
            throw new Error('Invalid token received from server.');
          }
        } else {
          // Handle case when the user is not an admin
          if (data.message && data.message.includes('You do not have admin privileges')) {
            setErrorMessage(data.message); // Show the error message
            // Redirect to the user login page after 5 seconds
            setTimeout(() => {
              navigate('/login');
            }, 5000);
          } else {
            setErrorMessage(data.message || 'Login failed. Please check your credentials.');
          }
          break;
        }
      } catch (error) {
        console.error('Login error:', error);
        if (attempt < maxAttempts) {
          setErrorMessage(`An error occurred. Retrying... (${attempt}/${maxAttempts})`);
        } else {
          setErrorMessage('An error occurred while logging in. Please try again later.');
        }
        if (attempt < maxAttempts) {
          // Wait for 2 seconds before retrying
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }
    }

    setLoading(false); // End loading state
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  const navigateToAdminLogin = () => {
    navigate('/login');
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Welcome Back Admin!</h2>
        <p>Login to your account to access your learning journey</p>
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
              required
            />
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error messages */}

          <button type="submit" className="login-btn" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>

        <div className="register-prompt">
          <p>
            Don't have an account?{' '}
            <span onClick={navigateToRegister} className="register-link">
              Register here
            </span>
          </p>
        </div>

        <button onClick={navigateToAdminLogin} className="admin-login-btn">
          Login As User
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
