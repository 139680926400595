import React, { useState } from 'react';
import '../css/ManageCouponPage.css'; // Custom CSS for styling
import config from '../../constants';

const GenerateCouponPage = () => {
  const [discountAmount, setDiscountAmount] = useState('');
  const [generatedCoupon, setGeneratedCoupon] = useState(null);
  const [manualCouponCode, setManualCouponCode] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle generating a coupon
  const handleGenerateCoupon = async () => {
    setError(null);
    setMessage(null);

    if (!discountAmount || isNaN(discountAmount)) {
      setError('Please enter a valid discount amount.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login'; // Redirect to login if no token exists
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${config.baseUrl}/admin/generate-coupon`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ discountAmount }),
      });

      const data = await response.json();

      if (response.ok) {
        setGeneratedCoupon(data.couponCode); // Display the unhashed coupon
        setMessage('Coupon generated successfully.');
      } else {
        setError(data.message || 'Error generating coupon.');
      }
    } catch (err) {
      setError('Error generating coupon. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Handle adding a manually entered coupon
  const handleAddManualCoupon = async () => {
    setError(null);
    setMessage(null);

    if (!manualCouponCode.trim()) {
      setError('Please enter a valid coupon code.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login'; // Redirect to login if no token exists
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${config.baseUrl}/admin/add-manual-coupon`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          couponCode: manualCouponCode,
          discountAmount,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Manual coupon added successfully.');
        setManualCouponCode(''); // Clear the input field
      } else {
        setError(data.message || 'Error adding manual coupon.');
      }
    } catch (err) {
      setError('Error adding manual coupon. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="generate-coupon-page">
      <h1>Generate Coupon</h1>

      <div className="form">
        <label htmlFor="discount-amount">Discount Amount ($):</label>
        <input
          type="text"
          id="discount-amount"
          value={discountAmount}
          onChange={(e) => setDiscountAmount(e.target.value)}
          placeholder="Enter discount amount"
        />

        <button onClick={handleGenerateCoupon} disabled={loading}>
          {loading ? 'Generating...' : 'Generate Coupon'}
        </button>
      </div>

      {generatedCoupon && (
        <div className="generated-coupon">
          <p><strong>Generated Coupon Code:</strong> {generatedCoupon}</p>
        </div>
      )}

      <div className="add-manual-coupon">
        <h2>Add New Coupon</h2>
        <input
          type="text"
          placeholder="Enter manual coupon code"
          value={manualCouponCode}
          onChange={(e) => setManualCouponCode(e.target.value)}
        />
        <button onClick={handleAddManualCoupon} disabled={loading}>
          {loading ? 'Adding...' : 'Add Coupon'}
        </button>
      </div>

      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default GenerateCouponPage;
