import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import AdminLoginPage from './components/admin/AdminLoginPage';
import DashboardPage from './components/DashboardPage';
import SubtopicsPage from './components/SubtopicsPage';
import QuizPage from './components/QuizPage';
import RegisterPage from './components/RegisterPage';
import VisitProfile from './components/VisitProfile';
import WalletPage from './components/WalletPage';
import LeaderboardPage from './components/LeaderboardPage';
import ProfilePage from './components/ProfilePage';
import OfferPage from './components/OfferPage';
import GrandTestPage from './components/GrandTestPage';
import QuizResult from './components/QuizResult';
import BookmarkPage from './components/BookmarkPage';
import QbankPage from './components/QbankPage';
import ManageQuestions from './components/admin/ManageQuestions';
import AdminPage from './components/admin/AdminPage';
import ManageSubjects from './components/admin/ManageSubjects';
import ManageSubtopics from './components/admin/ManageSubtopics';
import ManageCouponPage from './components/admin/ManageCouponPage';
import EditQuestions from './components/admin/EditQuestions';
import ManageDailyMCQ from './components/admin/ManageDailyMCQ';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin-login" element={<AdminLoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/:subjectName" element={<SubtopicsPage />} />
        <Route path="/quiz/:subjectName/:subtopicName" element={<QuizPage />} />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/offers" element={<OfferPage />} />
        <Route path="/grandtests" element={<GrandTestPage />} />
        <Route path="/wallet" element={<WalletPage />} />
        <Route path="/quiz-result" element={<QuizResult />} />
        <Route path="/manageqbank" element={<ManageQuestions />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/manage-subjects" element={<ManageSubjects />} />
        <Route path="/manage-subtopics" element={<ManageSubtopics />} />
        <Route path="/manage-coupons" element={<ManageCouponPage />} />
        <Route path="/getbookmarks" element={<BookmarkPage />} />
        <Route path="/qbankpage" element={<QbankPage />} />
        <Route path="/editquestions" element={<EditQuestions />} />
        <Route path="/managedailymcq" element={<ManageDailyMCQ />} />
        <Route path="/visitprofile/:userId" element={<VisitProfile />} />
      </Routes>
    </Router>
  );
};

export default App;
