import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../constants';
import '../css/ManageDailyMCQ.css';

const ManageDailyMCQ = () => {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([{ id: null, text: '' }]);
  const [correctOptionId, setCorrectOptionId] = useState(null);
  const [explanation, setExplanation] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const fetchDailyMCQ = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          window.location.href = '/admin-login';
          return;
        }

        const response = await axios.get(`${config.baseUrl}/getdaily-mcq`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;
        setQuestion(data.question);
        setOptions(data.options.map((option) => ({ id: option.id, text: option.text })));
        setCorrectOptionId(data.correctOptionId);
        setExplanation(data.explanation);
        setImagePreview(data.imageUrl || null);
      } catch (error) {
        console.error('Error fetching daily MCQ:', error);
        alert('Failed to fetch the daily MCQ.');
      }
    };

    fetchDailyMCQ();
  }, []);

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };

  const addOption = () => {
    if (options.length >= 4) {
      alert('You can only have a maximum of 4 options.');
      return;
    }
    const newOption = { id: `new-${Date.now()}`, text: '' }; // Temporary unique ID
    setOptions([...options, newOption]);
  };

  const removeOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!question || options.some((option) => !option.text) || !correctOptionId || !explanation) {
      alert('Please fill in all required fields.');
      return;
    }

    const formData = new FormData();
    formData.append('question', question);
    formData.append('options', JSON.stringify(options));
    formData.append('correctOptionId', correctOptionId);
    formData.append('explanation', explanation);
    if (imageFile) {
      formData.append('image', imageFile);
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert('You must be logged in to update the question.');
      window.location.href = '/admin-login';
      return;
    }

    try {
      const response = await axios.put(`${config.baseUrl}/update-mcq`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      alert(response.data.message);
    } catch (error) {
      console.error('Error updating the MCQ:', error);
      alert('Failed to update the daily MCQ.');
    }
  };

  return (
    <div className="manage-daily-mcq-container">
      <h2>Manage Daily MCQ</h2>
      <form className="manage-daily-mcq-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Question:</label>
          <textarea
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Image (optional):</label>
          <input type="file" accept="image/*" onChange={handleImageUpload} />
          {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
        </div>
        <div className="form-group">
          <label>Options:</label>
          {options.map((option, index) => (
            <div key={index} className="option-group">
              <input
                type="text"
                placeholder={`Option ${index + 1}`}
                value={option.text}
                onChange={(e) => handleOptionChange(index, 'text', e.target.value)}
              />
              <input
                type="radio"
                name="correctOption"
                checked={correctOptionId === option.id}
                onChange={() => setCorrectOptionId(option.id)}
              />
              <span>Correct</span>
              {options.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeOption(index)}
                  className="remove-option-btn"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button type="button" className="add-option-btn" onClick={addOption}>
            Add Option
          </button>
        </div>
        <div className="form-group">
          <label>Explanation:</label>
          <textarea
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
          ></textarea>
        </div>
        <button className="submit-btn" type="submit">
          Update MCQ
        </button>
      </form>
    </div>
  );
};

export default ManageDailyMCQ;
