import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuBar from './MenuBar';
import TopBar from './TopBar';
import './css/UserProfile.css';
import defaultProfileImage from './profile.png';
import config from '../constants';
import { FaWhatsapp, FaFacebook, FaTelegram, FaInstagram, FaMedal } from 'react-icons/fa';

const VisitProfilePage = () => {
  const { userId } = useParams(); // Extract userId from URL params
  const [userData, setUserData] = useState(null);
  const [userRank, setUserRank] = useState({ totalSolved: 0, rank: 0 });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      console.error('No user ID provided');
      navigate('/leaderboard'); // Redirect if no userId is provided
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');

        if (!token) {
          console.error('No token found');
          navigate('/login');
          return;
        }

        // Fetch user profile data
        const profileResponse = await axios.get(`${config.baseUrl}/api/user-profile/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(profileResponse.data);

        // Fetch user rank data
        const rankResponse = await axios.get(`${config.baseUrl}/getUserRank/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserRank(rankResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>User not found.</div>;
  }

  const profileImage = userData.profImage ? userData.profImage : defaultProfileImage;

  return (
    <div className="user-profile-page">
      <TopBar />

      <div className="profile-header">
        <div className="profile-image">
          <div className="profile-image-container">
            <img src={profileImage} alt="Profile" className="profile-img" />
          </div>
        </div>
        <div className="profile-info">
          <h2 className="name">{userData.firstName} {userData.lastName}</h2>
        </div>
      </div>

      {/* Rank Card */}
      <div className="rank-card">
        <FaMedal className="gold-medal-icon" />
        <h3>Rank: {userRank.rank}</h3>
      </div>

      <div className="profile-details">
        <div className="detail-item">
          <span className="label">Occupation:</span>
          <span className="value">{userData.occupation}</span>
        </div>
        <div className="detail-item">
          <span className="label">Location:</span>
          <span className="value">{userData.location}</span>
        </div>
      </div>

      {/* Social Media Share Section */}
      <div className="social-share">
        <h3>Share on:</h3>
        <div className="share-icons">
          <FaWhatsapp
            className="share-icon whatsapp"
            onClick={() => window.open(`https://wa.me/?text=${encodeURIComponent('Hey, I am sharing The Best QBank website to you. Checkout this link: https://qbankapp.in.net/')}`, '_blank')}
            title="Share on WhatsApp"
          />
          <FaFacebook
            className="share-icon facebook"
            onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('Hey, I am sharing The Best QBank website to you. Checkout this link: https://qbankapp.in.net/')}`, '_blank')}
            title="Share on Facebook"
          />
          <FaTelegram
            className="share-icon telegram"
            onClick={() => window.open(`https://t.me/share/url?url=${encodeURIComponent('Hey, I am sharing The Best QBank website to you. Checkout this link: https://qbankapp.in.net/')}`, '_blank')}
            title="Share on Telegram"
          />
          <FaInstagram
            className="share-icon instagram"
            onClick={() => alert('Instagram does not support direct web-based sharing. Please share the link manually.')}
            title="Share on Instagram"
          />
        </div>
      </div>

      <MenuBar />
    </div>
  );
};

export default VisitProfilePage;
