import React, { useEffect, useState } from 'react';
import './css/WalletPage.css'; // Updated CSS file for better styling
import MenuBar from './MenuBar'; // Import the shared MenuBar component
import config from '../constants'; // Import the config object
import TopBar from './TopBar';


const WalletPage = () => {
  const [walletBalance, setWalletBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    // Fetch wallet balance on component mount
    const fetchWalletBalance = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        return;
      }

      try {
        const response = await fetch(`${config.baseUrl}/wallet/balance`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          setWalletBalance(data.balance);
        } else {
          setError(data.message || 'Failed to fetch wallet balance.');
        }
      } catch {
        setError('Error fetching wallet balance. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchWalletBalance();
  }, []);

  const handleCouponSubmit = async () => {
    setMessage(null);
    const token = localStorage.getItem('token');

    if (!couponCode) {
      setMessage('Please enter a valid coupon code.');
      return;
    }

    try {
      const response = await fetch(`${config.baseUrl}/wallet/add-money`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ couponCode }),
      });

      const data = await response.json();

      if (response.ok) {
        setWalletBalance(data.newBalance);
        setMessage('Money added successfully!');
        setCouponCode('');
      } else {
        setMessage(data.message || 'Failed to add money.');
      }
    } catch {
      setMessage('Error adding money. Please try again later.');
    }
  };

  return (
    <div className="wallet-page-container">
      <TopBar /> {/* TopBar at the top */}

      <div className="wallet-page-content">
        <div className="wallet-header">
          <h1>My Wallet</h1>
        </div>

        {loading ? (
          <p className="loading-message">Loading wallet balance...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div className="wallet-info">
            <h4><strong>Balance:</strong> ₹{walletBalance}</h4>
          </div>
        )}

        <div className="coupon-section">
          <h5>Add Balance Using a Coupon Code</h5>
          <div className="coupon-input-container">
            <input
              type="text"
              placeholder="Enter coupon code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              className="coupon-input"
            />
            <button onClick={handleCouponSubmit} className="action-button">Redeem</button>
          </div>
          <button
            onClick={() => window.open("https://subspace.in.net/product/gift-card/", "_blank")}
            className="action-button secondary-button"
          >
            Buy Gift Card Coupon Code
          </button>
        </div>

        {message && <p className="message">{message}</p>}

        <MenuBar />
      </div>
    </div>
  );
};

export default WalletPage;
