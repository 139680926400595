import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../constants';
import '../css/EditQuestions.css';

const EditQuestions = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [options, setOptions] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      window.location.href = '/adminlogin';
    }
  }, [token]);

  // Search questions based on the query
  const handleSearch = () => {
    axios
      .get(`${config.baseUrl}/searchquestions?query=${searchQuery}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => setQuestions(response.data))
      .catch(error => console.error('Error fetching questions!', error));
  };

  // Fetch question details when a question is selected
  const fetchQuestionDetails = questionId => {
    axios
      .get(`${config.baseUrl}/questiondetails/${questionId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data?.question) {
          setSelectedQuestion(response.data.question);
          setOptions(response.data.options || []);
        }
      })
      .catch(error => console.error('Error fetching question details!', error));
  };

  // Update option field value
  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };

  // Handle image file upload
  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  // Handle form submission to update question
  const handleUpdate = async e => {
    e.preventDefault();
    if (!selectedQuestion || options.some(option => !option.option_text.trim())) {
      alert('Please fill in all fields.');
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('questionText', selectedQuestion.question_text);
    formData.append(
      'options',
      JSON.stringify(
        options.map(option => ({
          id: option.id,
          option_text: option.option_text,
          is_correct: option.is_correct,
        }))
      )
    );
    if (imageFile) formData.append('image', imageFile);

    try {
      const response = await axios.post(
        `${config.baseUrl}/updatequestion/${selectedQuestion.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      alert(response.data.message);
      setSelectedQuestion(null);
      setOptions([]);
      setImageFile(null);
      setImagePreview(null);
    } catch (error) {
      console.error('Error updating question!', error);
      alert('Failed to update the question.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle question deletion
  const handleDelete = questionId => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      axios
        .delete(`${config.baseUrl}/deletequestion/${questionId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          alert(response.data.message);
          setSelectedQuestion(null);
          setQuestions(prev => prev.filter(q => q.id !== questionId));
        })
        .catch(error => console.error('Error deleting question!', error));
    }
  };

  // Add new option
  const handleAddOption = () => {
    if (options.some(option => !option.option_text.trim())) {
      alert('Please fill in all existing options before adding a new one.');
      return;
    }
    setOptions([...options, { id: null, option_text: '', is_correct: false }]);
  };

  // Delete an option
  const handleOptionDelete = index => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  return (
    <div className="edit-questions-container">
      <h2>Edit Questions</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by question text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      <div className="questions-list">
        {questions.length ? (
          questions.map(question => (
            <div
              key={question.id}
              className="question-item"
              onClick={() => fetchQuestionDetails(question.id)}
            >
              {question.question_text}
            </div>
          ))
        ) : (
          <p>Type any question keyword and then click Search</p>
        )}
      </div>

      {selectedQuestion && (
        <form className="edit-question-form" onSubmit={handleUpdate}>
          <div className="form-group">
            <label>Question Text</label>
            <textarea
              value={selectedQuestion.question_text}
              onChange={e => setSelectedQuestion({ ...selectedQuestion, question_text: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Options</label>
            {options.map((option, index) => (
              <div key={index} className="option-group">
                <input
                  type="text"
                  value={option.option_text}
                  onChange={e => handleOptionChange(index, 'option_text', e.target.value)}
                  placeholder={`Option ${index + 1}`}
                  required
                />
                <input
                  type="checkbox"
                  checked={option.is_correct}
                  onChange={e => handleOptionChange(index, 'is_correct', e.target.checked)}
                />
                <span>Correct</span>
                <button
                  type="button"
                  onClick={() => handleOptionDelete(index)}
                  className="delete-option"
                >
                  Delete
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddOption}>Add Option</button>
          </div>

          <div className="form-group">
            <label>Image</label>
            <input type="file" onChange={handleImageUpload} />
            {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
          </div>

          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Update Question'}
          </button>

          {/* Delete Question Button */}
          <button
            type="button"
            className="delete-question-btn"
            onClick={() => handleDelete(selectedQuestion.id)}
          >
            Delete Question
          </button>
        </form>
      )}
    </div>
  );
};

export default EditQuestions;
