import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TopBar from './TopBar'; // Import the TopBar component
import MenuBar from './MenuBar'; // Import the MenuBar component
import './css/Leaderboard.css'; // Import the CSS file for the leaderboard page
import config from '../constants'; // Import config containing baseUrl

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [currentUserRank, setCurrentUserRank] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      navigate('/login');
      return;
    }

    // Fetch leaderboard data (all users' ranks)
    axios
      .get(`${config.baseUrl}/getLeaderboard`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLeaderboard(response.data);
      })
      .catch((error) => {
        console.error('Error fetching leaderboard:', error.response || error);
      });

    // Fetch current user's rank
    axios
      .get(`${config.baseUrl}/getUserRank`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setCurrentUserRank(response.data.rank); // Set current user's rank
      })
      .catch((error) => {
        console.error('Error fetching user rank:', error.response || error);
      });
  }, [navigate]);

  const handleCardClick = (userId) => {
    navigate(`/visitprofile/${userId}`); // Navigate to VisitProfilePage with userId
  };

  return (
    <div className="leaderboard-page">
      <TopBar />
      <h1 className="leaderboard-title">Leaderboard</h1>

      <div className="leaderboard">
        {leaderboard.map((user) => (
          <div
            key={user.id}
            className={`leaderboard-entry ${user.rank === currentUserRank ? 'current-user-entry' : ''}`}
            onClick={() => handleCardClick(user.id)} // Handle card click
          >
            <div className="rank">{user.rank}</div>
            <div className="username">{user.first_name}</div>
          </div>
        ))}
      </div>

      <MenuBar />
    </div>
  );
};

export default Leaderboard;
