import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../constants'; // Import config containing baseUrl
import '../css/ManageSubjects.css'; // Import the CSS for styling

const ManageSubjects = () => {
  const [subjects, setSubjects] = useState([]);
  const [newSubject, setNewSubject] = useState('');
  const [editingSubject, setEditingSubject] = useState(null);
  const [editedSubjectName, setEditedSubjectName] = useState('');

  // Fetch subjects on initial load if a token exists
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/adminlogin';
    } else {
      fetchSubjects(token);
    }
    
    // Retrieve 'newSubject' from localStorage if it exists
    const storedSubject = localStorage.getItem('newSubject');
    if (storedSubject) {
      setNewSubject(storedSubject);
    }
  }, []);

  // Save 'newSubject' to localStorage whenever it changes
  useEffect(() => {
    if (newSubject) {
      localStorage.setItem('newSubject', newSubject);
    }
  }, [newSubject]);

  const fetchSubjects = token => {
    axios
      .get(`${config.baseUrl}/getsubjects`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => setSubjects(response.data))
      .catch(err => console.error('Error fetching subjects:', err));
  };

  const handleAddSubject = () => {
    if (!newSubject.trim()) {
      alert('Subject name cannot be empty');
      return;
    }

    const token = localStorage.getItem('token');
    axios
      .post(
        `${config.baseUrl}/addsubject`,
        { name: newSubject },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(response => {
        alert(response.data.message);
        setNewSubject('');
        localStorage.removeItem('newSubject');  // Clear 'newSubject' from localStorage after submission
        fetchSubjects(token);
      })
      .catch(err => {
        console.error('Error adding subject:', err);
        alert('Error adding subject!');
      });
  };

  const handleEditSubject = id => {
    setEditingSubject(id);
    const subject = subjects.find(s => s.id === id);
    setEditedSubjectName(subject.name);
  };

  const handleSaveEdit = id => {
    if (!editedSubjectName.trim()) {
      alert('Subject name cannot be empty');
      return;
    }

    const token = localStorage.getItem('token');
    axios
      .put(
        `${config.baseUrl}/updatesubject/${id}`,
        { name: editedSubjectName },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(response => {
        alert(response.data.message);
        setEditingSubject(null);
        fetchSubjects(token);
      })
      .catch(err => {
        console.error('Error updating subject:', err);
        alert('Error updating subject!');
      });
  };

  const handleDeleteSubject = id => {
    if (!window.confirm('Are you sure you want to delete this subject?')) return;

    const token = localStorage.getItem('token');
    axios
      .delete(`${config.baseUrl}/deletesubject/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        alert(response.data.message);
        fetchSubjects(token);
      })
      .catch(err => {
        console.error('Error deleting subject:', err);
        alert('Error deleting subject!');
      });
  };

  return (
    <div className="manage-subjects-container">
      <h2>Manage Subjects</h2>
      <div className="add-subject-form">
        <input
          type="text"
          placeholder="Enter new subject name"
          value={newSubject}
          onChange={e => setNewSubject(e.target.value)}
        />
        <button onClick={handleAddSubject}>Add Subject</button>
      </div>
      <table className="subjects-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map(subject => (
            <tr key={subject.id}>
              <td>{subject.id}</td>
              <td>
                {editingSubject === subject.id ? (
                  <input
                    type="text"
                    value={editedSubjectName}
                    onChange={e => setEditedSubjectName(e.target.value)}
                  />
                ) : (
                  subject.name
                )}
              </td>
              <td>
                {editingSubject === subject.id ? (
                  <>
                    <button onClick={() => handleSaveEdit(subject.id)}>Save</button>
                    <button onClick={() => setEditingSubject(null)}>Cancel</button>
                  </>
                ) : (
                  <>
                    <button onClick={() => handleEditSubject(subject.id)}>Edit</button>
                    <button onClick={() => handleDeleteSubject(subject.id)}>Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageSubjects;
