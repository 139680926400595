import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import MenuBar from './MenuBar';
import TopBar from './TopBar';
import './css/UserProfile.css';
import defaultProfileImage from './profile.png';
import config from '../constants';
import { FaWhatsapp, FaFacebook, FaTelegram, FaInstagram, FaMedal } from 'react-icons/fa';

const UserProfile = ({ isCurrentUser = true }) => {
  const [userData, setUserData] = useState(null);
  const [userRank, setUserRank] = useState({ totalSolved: 0, rank: 0 });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams(); // Get userId from URL params

  const shareUrl = 'https://qbankapp.in.net/';
  const shareText = `Hey, I am sharing The Best QBank website to you: QBankApp!`;

  // Memoized handleLogout to avoid re-creating the function on every render
  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      handleLogout(); // Handle logout if no token
      return;
    }

    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/user-profile/${userId || ''}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error.response || error);
      }
    };

    const fetchUserRank = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/getUserRank/${userId || ''}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setUserRank(response.data);
      } catch (error) {
        console.error('Error fetching user rank:', error.response || error);
      }
    };

    fetchUserProfile();
    fetchUserRank();
  }, [userId, handleLogout]); // Added handleLogout to the dependencies

  const handleShare = async (platform) => {
    const encodedUrl = encodeURIComponent(shareUrl);
    const encodedText = encodeURIComponent(shareText);

    const shareLinks = {
      whatsapp: `https://api.whatsapp.com/send?text=${encodedText}%20${encodedUrl}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      telegram: `https://t.me/share/url?url=${encodedUrl}&text=${encodedText}`,
      instagram: shareUrl, // Instagram doesn't support direct URL sharing
    };

    if (platform === 'native' && navigator.share) {
      try {
        await navigator.share({
          title: 'Subspace',
          text: shareText,
          url: shareUrl,
        });
      } catch (error) {
        console.error('Error using native share:', error);
      }
    } else if (platform in shareLinks) {
      window.open(shareLinks[platform], '_blank');
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);

    const formData = new FormData();
    formData.append('profileImage', file);

    try {
      const response = await axios.post(`${config.baseUrl}/api/upload-profile-image`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const newImageUrl = response.data.imageUrl;
      await axios.put(
        `${config.baseUrl}/api/update-profile-image`,
        { profImage: newImageUrl },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      setUserData((prevState) => ({
        ...prevState,
        profImage: newImageUrl,
      }));
    } catch (error) {
      console.error('Error uploading the profile image:', error);
    } finally {
      setLoading(false);
    }
  };

  const profileImage = userData?.profImage || defaultProfileImage;

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-profile-page">
      <TopBar />
      <div className="profile-header">
        <div className="profile-image" onClick={() => document.getElementById('file-upload').click()}>
          <div className="profile-image-container">
            <img src={profileImage} alt="Profile" className="profile-img" />
          </div>
        </div>
        <div className="profile-info">
          <h2 className="name">{userData.firstName} {userData.lastName}</h2>
          <p className="email">{userData.email}</p>
        </div>
      </div>      
      <div className="rank-card">
        <FaMedal className="gold-medal-icon" />
        <h3>Rank: {userRank.rank}</h3>
      </div>

      <div className="profile-details">
        <div className="detail-item">
          <span className="label">Occupation:</span>
          <span className="value">{userData.occupation}</span>
        </div>
        <div className="detail-item">
          <span className="label">Location:</span>
          <span className="value">{userData.location}</span>
        </div>
      </div>

      {isCurrentUser && (
        <div className="social-share">
          <h3>Share on:</h3>
          <div className="share-icons">
            <FaWhatsapp
              className="share-icon whatsapp"
              title="Share on WhatsApp"
              onClick={() => handleShare('whatsapp')}
            />
            <FaFacebook
              className="share-icon facebook"
              title="Share on Facebook"
              onClick={() => handleShare('facebook')}
            />
            <FaTelegram
              className="share-icon telegram"
              title="Share on Telegram"
              onClick={() => handleShare('telegram')}
            />
            <FaInstagram
              className="share-icon instagram"
              title="Share on Instagram"
              onClick={() => handleShare('instagram')}
            />
          </div>
        </div>
      )}

      <MenuBar />
      
      {/* Loading Overlay */}
      {loading && <div className="loading-overlay">Uploading...</div>}

      <input
        id="file-upload"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />
    </div>
  );
};

export default UserProfile;
