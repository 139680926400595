import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './css/BookmarkPage.css'; // Import the relevant CSS for styling
import config from '../constants'; // Import config containing baseUrl
import MenuBar from './MenuBar'; // Import the shared MenuBar component

const BookmarkPage = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      navigate('/login'); // Redirect to login if the token is not found
      return;
    }

    const fetchBookmarks = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/getbookmarks`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setBookmarks(response.data.bookmarks || []);
      } catch (error) {
        console.error('Error fetching bookmarks:', error);

        if (
          error.response &&
          (error.response.status === 400 || error.response.status === 404)
        ) {
          setError(error.response.data.message);
        } else {
          setError('There was an error fetching the bookmarks.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBookmarks();
  }, [navigate]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="bookmark-page">
      <h1>Your Bookmarks</h1>
      {bookmarks.length === 0 ? (
        <p>You don't have any bookmarks.</p>
      ) : (
        <div className="bookmark-list">
          {bookmarks.map((bookmark) => (
            <div key={bookmark.id} className="bookmark-card">
              <h3>{bookmark.question_text}</h3>
              <div className="options">
                {JSON.parse(bookmark.options || '[]').map((option) => (
                  <p key={option.option_id}>
                    <strong>{option.option_text}</strong>{' '}
                    {option.is_correct ? '(Correct)' : ''}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      <MenuBar /> {/* Bottom menu bar */}
    </div>
  );
};

export default BookmarkPage;
