import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import config from '../constants';
import './css/QuizResult.css';
import MenuBar from './MenuBar'; // Import the shared MenuBar component
import TopBar from './TopBar';



const QuizResultPage = () => {
  const { baseUrl } = config;
  const location = useLocation();
  const navigate = useNavigate();

  const [quizResult, setQuizResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookmarkedQuestions, setBookmarkedQuestions] = useState(new Set());

  useEffect(() => {
    const fetchQuizResult = async () => {
      const token = localStorage.getItem('token');
      const resultId = location.state?.resultId;

      if (!token || !resultId) {
        navigate('/login');
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/quiz-result/${resultId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        if (response.ok) {
          setQuizResult(data);
        } else {
          setError(data.message || 'Error fetching quiz results.');
        }
      } catch (err) {
        setError('Failed to fetch quiz results. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuizResult();
  }, [baseUrl, location, navigate]);

  const handleBookmark = async (question) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/bookmarks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ question }),
      });

      if (response.ok) {
        setBookmarkedQuestions((prev) => new Set(prev).add(question.question_id));
      } else {
        const errorData = await response.json();
        console.error('Error bookmarking question:', errorData.message);
      }
    } catch (err) {
      console.error('Failed to bookmark question:', err);
    }
  };

  if (loading) {
    return (
      <div className="loading-screen">
        Calculating Your Score
        <div className="rotating-circle"></div>
      </div>
    );
  }
  if (error) {
    return <div className="error-screen">Error: {error}</div>;
  }

  if (!quizResult) {
    return <div className="error-screen">No quiz results available.</div>;
  }

  const { correctCount, incorrectCount, skippedCount, questions } = quizResult;

  return (
    <div className="quiz-result-page">
      <TopBar /> {/* TopBar at the top */}
      <div className="quiz-header">
        <img src={logo} alt="Quiz Logo" className="quiz-logo" />
        <h1>Quiz Results</h1>
      </div>

      <div className="quiz-summary">
        <div className="summary-card correct">
          <h2>Correct</h2>
          <p>{correctCount}</p>
        </div>
        <div className="summary-card incorrect">
          <h2>Incorrect</h2>
          <p>{incorrectCount}</p>
        </div>
        <div className="summary-card skipped">
          <h2>Skipped</h2>
          <p>{skippedCount}</p>
        </div>
      </div>

      <div className="quiz-review">
        <h2>Review Questions</h2>
        {questions.map((q, index) => (
          <div key={q.question_id} className="question-card">
            <button
              className="bookmark-icon"
              onClick={() => handleBookmark(q)}
              disabled={bookmarkedQuestions.has(q.question_id)}
              title={bookmarkedQuestions.has(q.question_id) ? 'Bookmarked' : 'Bookmark'}
            >
              {bookmarkedQuestions.has(q.question_id) ? '★' : '☆'}
            </button>
            <h3>
              {index + 1}. {q.question_text}
            </h3>
            {q.q_image && (
              <img src={q.q_image} alt="Question" className="review-question-image" />
            )}
            <div className="options">
              {q.options.map((option) => (
                <div
                  key={option.option_id}
                  className={`option ${
                    option.is_correct
                      ? 'correct'
                      : option.option_id === q.selected_option_id
                      ? 'selected'
                      : ''
                  }`}
                >
                  {option.option_text}
                  {option.is_correct && <span className="badge">Correct</span>}
                  {option.option_id === q.selected_option_id && (
                    <span className="badge">
                      {option.is_correct ? 'Your Answer' : 'Incorrect'}
                    </span>
                  )}
                </div>
              ))}
            </div>
            {q.selected_option_id === null && (
              <div className="skipped-badge">You skipped this question.</div>
            )}
          </div>
        ))}
      </div>

      <div className="quiz-footer">
        <button className="back-button" onClick={() => navigate('/')}>
          Back to Home
        </button>
      </div>

      {/* Bottom MenuBar */}
      <MenuBar /> {/* Add the MenuBar component here */}
    </div>
  );
};

export default QuizResultPage;
