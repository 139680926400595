import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuBar from './MenuBar';
import TopBar from './TopBar';
import './css/QbankPage.css';
import config from '../constants';
import BookmarkIcon from './bookmark-icon.png'; // Replace with the actual path to the icon

const QbankPage = () => {
  const [subjects, setSubjects] = useState([]);
  const [totalSolved, setTotalSolved] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      handleLogout();
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch subjects
        const subjectsResponse = await axios.get(`${config.baseUrl}/getqbanksubjects`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSubjects(subjectsResponse.data || []);

        // Fetch total subtopics solved
        const solvedResponse = await axios.get(`${config.baseUrl}/getTotalSubtopicsSolved`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTotalSolved(solvedResponse.data.totalSolved || 0);
      } catch (error) {
        console.error('Error fetching data:', error.response || error);
        if (error.response?.status === 401 || error.response?.status === 403) {
          alert('Your session has expired. Please log in again.');
          handleLogout();
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [handleLogout]); // Add handleLogout to dependencies array

  const handleSubjectClick = (subjectName) => {
    navigate(`/${encodeURIComponent(subjectName)}`);
  };

  return (
    <div className="qbank-page">
      <TopBar />
      <div className="qbank-content">
        {/* Top Cards Section */}
        <div className="top-cards">
          <div className="top-card" onClick={() => navigate('/getbookmarks')}>
            <div className="icon-text">
              <img src={BookmarkIcon} alt="Bookmark Icon" className="bookmark-icon" />
              <h3>Bookmarks</h3>
            </div>
          </div>
          <div className="top-card">
            <h3>Solved</h3>
            <p>{totalSolved} Modules</p> {/* Display total solved subtopics */}
          </div>
          <div className="top-card" onClick={() => navigate('/custom-module')}>
            <h3>Custom Module</h3>
          </div>
        </div>

        {/* Subject List Section */}
        <div className="subject-list">
          {loading ? (
            <p>Loading subjects...</p>
          ) : subjects.length > 0 ? (
            subjects.map((subject) => (
              <div
                key={subject.id || subject.name}
                className="subject-card"
                onClick={() => handleSubjectClick(subject.name)}
              >
                <h3>{subject.name || 'Unknown Subject'}</h3>
                <p>{subject.total_subtopics} Modules</p>
              </div>
            ))
          ) : (
            <p>No subjects available.</p>
          )}
        </div>

        <MenuBar />
      </div>
    </div>
  );
};

export default QbankPage;
