import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/LoginPage.css';
import config from '../constants';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOverlay, setShowOverlay] = useState(false); // For the overlay
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const preloadDashboard = () => {
    // Preload dashboard data (e.g., fetch API, preload dashboard resources, etc.)
    fetch(`${config.baseUrl}/daily-mcq`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        console.log('Dashboard preloaded:', data);
      })
      .catch(error => {
        console.error('Error preloading dashboard:', error);
      });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch(`${config.baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        if (data.token) {
          localStorage.setItem('token', data.token);
          setShowOverlay(true); // Show the overlay
          preloadDashboard(); // Start preloading the dashboard

          setTimeout(() => {
            setShowOverlay(false);
            navigate('/dashboard');
          }, 3000); // Display overlay for 3 seconds
        } else {
          throw new Error('Invalid token received from server.');
        }
      } else {
        setErrorMessage(data.message || 'Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('An error occurred while logging in. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  const navigateToAdminLogin = () => {
    navigate('/admin-login');
  };

  return (
    <div className="login-page">
      {showOverlay && (
        <div className="overlay">
          <h1>
            For the Medicos{"\n"}By the Medicos
          </h1>
        </div>
      )}
      <div className={`login-container ${showOverlay ? 'blur' : ''}`}>
        <h2>Welcome Back!</h2>
        <p>Login to your account to access your learning journey</p>
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit" className="login-btn" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        <div className="register-prompt">
          <p>
            Don't have an account?{' '}
            <span onClick={navigateToRegister} className="register-link">
              Register here
            </span>
          </p>
        </div>
        <button onClick={navigateToAdminLogin} className="admin-login-btn">
          Admin Login
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
