import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../constants';
import '../css/ManageQuestions.css';

const ManageQuestions = () => {
  const [subjects, setSubjects] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedSubtopic, setSelectedSubtopic] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [options, setOptions] = useState([{ text: '', isCorrect: false }]);
  const [imageFile, setImageFile] = useState(null); // Track uploaded image file
  const [imagePreview, setImagePreview] = useState(null); // Preview image

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem('manageQuestionsState'));
    if (savedState) {
      setSubjects(savedState.subjects || []);
      setSubtopics(savedState.subtopics || []);
      setSelectedSubject(savedState.selectedSubject || '');
      setSelectedSubtopic(savedState.selectedSubtopic || '');
      setQuestionText(savedState.questionText || '');
      setOptions(savedState.options || [{ text: '', isCorrect: false }]);
    } else {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/adminlogin';
      } else {
        axios
          .get(`${config.baseUrl}/getsubjects`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(response => setSubjects(response.data))
          .catch(error => console.error('Error fetching subjects!', error));
      }
    }
  }, []);

  useEffect(() => {
    const state = {
      subjects,
      subtopics,
      selectedSubject,
      selectedSubtopic,
      questionText,
      options,
    };
    localStorage.setItem('manageQuestionsState', JSON.stringify(state));
  }, [subjects, subtopics, selectedSubject, selectedSubtopic, questionText, options]);

  const fetchSubtopics = subjectId => {
    setSelectedSubject(subjectId);
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/adminlogin';
      return;
    }

    fetch(`${config.baseUrl}/subtopics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ subject_id: subjectId }),
    })
      .then(response => {
        if (!response.ok) throw new Error(`Error: ${response.statusText}`);
        return response.json();
      })
      .then(data => setSubtopics(data))
      .catch(error => {
        console.error('Error fetching subtopics:', error.message);
        alert(error.message);
      });
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];

    if (field === 'isCorrect') {
      // Make sure only one option can be the correct answer
      updatedOptions.forEach((option, i) => {
        if (i !== index) {
          option.isCorrect = false; // Uncheck all other options
        }
      });
    }

    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };

  const addOption = () => {
    if (options.length >= 4) {
      alert("You can only have a maximum of 4 options.");
      return;
    }
    setOptions([...options, { text: '', isCorrect: false }]);
  };

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file); // Store the image file for submission
      setImagePreview(URL.createObjectURL(file)); // Generate preview
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    // Check if at least one option is marked as correct
    if (!options.some(option => option.isCorrect)) {
      alert('Please select at least one correct option.');
      return;
    }

    // Ensure that other required fields are filled
    if (!selectedSubtopic || !questionText || options.some(option => !option.text)) {
      alert('Please fill in all fields.');
      return;
    }

    const formData = new FormData();
    formData.append('subtopicId', selectedSubtopic);
    formData.append('questionText', questionText);
    formData.append('options', JSON.stringify(options)); // Serialize options
    if (imageFile) {
      formData.append('image', imageFile); // Append the image file
    }

    const token = localStorage.getItem('token');

    if (!token) {
      alert('You must be logged in to submit questions');
      window.location.href = '/adminlogin';
      return;
    }

    try {
      const response = await axios.post(`${config.baseUrl}/addquestions`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data', // Required for file uploads
        },
      });
      alert(response.data.message);
      setQuestionText('');
      setOptions([{ text: '', isCorrect: false }]);
      setImageFile(null); // Reset the image file after submission
      setImagePreview(null); // Reset the image preview
    } catch (err) {
      console.error('Error submitting the question!', err);
      alert('Error submitting the question!');
    }
  };

  return (
    <div className="manage-questions-container">
      <h2>Manage Questions</h2>
      <form className="manage-questions-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Subject:</label>
          <select onChange={e => fetchSubtopics(e.target.value)} value={selectedSubject}>
            <option value="">Select Subject</option>
            {subjects.map(subject => (
              <option key={subject.id} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </select>
        </div>
        {subtopics.length > 0 && (
          <div className="form-group">
            <label>Subtopic:</label>
            <select
              onChange={e => setSelectedSubtopic(e.target.value)}
              value={selectedSubtopic}
            >
              <option value="">Select Subtopic</option>
              {subtopics.map(subtopic => (
                <option key={subtopic.id} value={subtopic.id}>
                  {subtopic.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedSubtopic && (
          <>
            <div className="form-group">
              <label>Question:</label>
              <textarea
                value={questionText}
                onChange={e => setQuestionText(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Image (optional):</label>
              <input type="file" accept="image/*" onChange={handleImageUpload} />
              {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
            </div>
            <div className="form-group">
              <label>Options:</label>
              {options.map((option, index) => (
                <div key={index} className="option-group">
                  <input
                    type="text"
                    placeholder={`Option ${index + 1}`}
                    value={option.text}
                    onChange={e => handleOptionChange(index, 'text', e.target.value)}
                  />
                  <input
                    type="checkbox"
                    checked={option.isCorrect}
                    onChange={e => handleOptionChange(index, 'isCorrect', e.target.checked)}
                  />
                  <span>Correct</span>
                </div>
              ))}
              <button type="button" className="add-option-btn" onClick={addOption}>
                Add Option
              </button>
            </div>
            <button className="submit-btn" type="submit">
              Save Question
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default ManageQuestions;
