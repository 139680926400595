import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../../constants'; // Import config containing baseUrl
import '../css/ManageSubtopics.css'; // Import the CSS file

const ManageSubtopics = () => {
  const [subjects, setSubjects] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [newSubtopic, setNewSubtopic] = useState('');

  // Fetch subjects on component load
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/adminlogin';
      return;
    }

    axios
      .get(`${config.baseUrl}/getsubjects`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => setSubjects(response.data))
      .catch(error => console.error('Error fetching subjects!', error));

    // Check if there is a previously selected subject or new subtopic in localStorage
    const storedSubject = localStorage.getItem('selectedSubject');
    const storedNewSubtopic = localStorage.getItem('newSubtopic');
    if (storedSubject) setSelectedSubject(storedSubject);
    if (storedNewSubtopic) setNewSubtopic(storedNewSubtopic);
  }, []);

  // Fetch subtopics when a subject is selected
  const fetchSubtopics = useCallback((subjectId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/adminlogin';
      return;
    }

    axios
      .post(
        `${config.baseUrl}/getsubtopics`,
        { subject_id: subjectId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(response => setSubtopics(response.data))
      .catch(error => console.error('Error fetching subtopics!', error));

    // Store the selected subject in localStorage
    localStorage.setItem('selectedSubject', subjectId);
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      fetchSubtopics(selectedSubject);
    }
  }, [selectedSubject, fetchSubtopics]); // Ensure `fetchSubtopics` is included in the dependency array

  const handleAddSubtopic = () => {
    if (!newSubtopic.trim()) {
      alert('Subtopic name cannot be empty!');
      return;
    }

    const token = localStorage.getItem('token');

    axios
      .post(
        `${config.baseUrl}/addsubtopic`,
        { subject_id: selectedSubject, name: newSubtopic },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        setNewSubtopic('');
        localStorage.removeItem('newSubtopic'); // Clear 'newSubtopic' from localStorage
        fetchSubtopics(selectedSubject); // Refresh subtopics
      })
      .catch(error => console.error('Error adding subtopic!', error));
  };

  const handleEditSubtopic = (id) => {
    const updatedName = prompt('Enter the new name for the subtopic:');
    if (!updatedName?.trim()) return;

    const token = localStorage.getItem('token');

    axios
      .put(
        `${config.baseUrl}/editsubtopic`,
        { id, name: updatedName },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => fetchSubtopics(selectedSubject)) // Refresh subtopics
      .catch(error => console.error('Error editing subtopic!', error));
  };

  const handleDeleteSubtopic = (id) => {
    if (!window.confirm('Are you sure you want to delete this subtopic?')) return;

    const token = localStorage.getItem('token');

    axios
      .delete(`${config.baseUrl}/deletesubtopic`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { id },
      })
      .then(() => fetchSubtopics(selectedSubject)) // Refresh subtopics
      .catch(error => console.error('Error deleting subtopic!', error));
  };

  return (
    <div className="manage-subtopics-container">
      <h2>Manage Subtopics</h2>
      <div className="form-group">
        <label>Select Subject:</label>
        <select onChange={e => setSelectedSubject(e.target.value)} value={selectedSubject}>
          <option value="">Select Subject</option>
          {subjects.map(subject => (
            <option key={subject.id} value={subject.id}>
              {subject.name}
            </option>
          ))}
        </select>
      </div>

      {selectedSubject && (
        <>
          <div className="subtopic-list">
            <h3>Subtopics</h3>
            <ul>
              {subtopics.map(subtopic => (
                <li key={subtopic.id} className="subtopic-item">
                  {subtopic.name}
                  <button onClick={() => handleEditSubtopic(subtopic.id)}>Edit</button>
                  <button onClick={() => handleDeleteSubtopic(subtopic.id)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>
          <div className="add-subtopic">
            <h4>Add New Subtopic</h4>
            <input
              type="text"
              placeholder="Enter subtopic name"
              value={newSubtopic}
              onChange={e => {
                setNewSubtopic(e.target.value);
                localStorage.setItem('newSubtopic', e.target.value); // Save newSubtopic to localStorage
              }}
            />
            <button onClick={handleAddSubtopic}>Add Subtopic</button>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageSubtopics;
