import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa'; // Import the down arrow icon
import SidePanel from './SidePanel';
import ManageQuestions from './ManageQuestions';
import ManageSubjects from './ManageSubjects';
import ManageSubtopics from './ManageSubtopics';
import ManageCouponPage from './ManageCouponPage';
import EditQuestions from './EditQuestions';
import ManageDailyMCQ from './ManageDailyMCQ';
import '../css/AdminPage.css';



const AdminPage = () => {
  const [selectedPage, setSelectedPage] = useState(''); // Tracks the selected panel
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Controls dropdown visibility
  const [profileImage, setProfileImage] = useState(null); // Stores admin profile image
  const [isLogoutVisible, setIsLogoutVisible] = useState(false); // Controls logout button visibility
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else if (window.location.pathname !== '/admin') {
      navigate('/admin');
    }

    const savedPage = localStorage.getItem('selectedPage');
    if (savedPage) {
      setSelectedPage(savedPage);
    }

    // Fetch admin profile image
    fetch('/get-admin-profile', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`, // Attach token for authentication
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setProfileImage(data.profileImage);
        }
      })
      .catch((err) => console.error('Error fetching profile image:', err));
  }, [navigate]);

  const handlePageSelection = (page) => {
    setSelectedPage(page);
    localStorage.setItem('selectedPage', page);
  };

  const renderMainPanel = () => {
    switch (selectedPage) {
      case 'Manage Questions':
        return <ManageQuestions />;
      case 'Manage Subjects':
        return <ManageSubjects />;
      case 'Manage Subtopics':
        return <ManageSubtopics />;
      case 'Manage Coupons':
        return <ManageCouponPage />;
      case 'Edit Questions':
        return <EditQuestions />;
      case 'Manage Daily MCQ':
        return <ManageDailyMCQ />;
      default:
        return <h2>Welcome to the Admin Panel</h2>;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('selectedPage');
    navigate('/admin-login');
  };

  const toggleLogoutVisibility = () => {
    setIsLogoutVisible(!isLogoutVisible);
  };

  const handleDropdownToggle = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleDropdownPageSelection = (page) => {
    setSelectedPage(page);
    localStorage.setItem('selectedPage', page);
    setIsDropdownVisible(false);
  };

  const handleClickOutside = (e) => {
    if (e.target.className === 'dropdown-overlay') {
      setIsDropdownVisible(false);
    }
  };

  return (
    <div className="admin-page">
      <SidePanel onSelectPage={handlePageSelection} />
      <div className="main-panel">{renderMainPanel()}</div>

      {/* Profile Section */}
      <div className="profile-section">
        {profileImage && (
          <img
            src={profileImage}
            alt="Admin Profile"
            className="profile-image"
            onClick={toggleLogoutVisibility}
          />
        )}
        {isLogoutVisible && (
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        )}
      </div>

      {/* Mobile Top Admin Bar with Toggle Arrow */}
      <div className="top-admin-bar">
        <div className="toggle-arrow" onClick={handleDropdownToggle}>
          <FaChevronDown />
        </div>
      </div>

      {/* Full-page dropdown (only visible on mobile devices) */}
      {isDropdownVisible && (
        <div className="dropdown-overlay" onClick={handleClickOutside}>
          <div className="dropdown-content">
            <button onClick={() => handleDropdownPageSelection('Manage Questions')}>Manage Questions</button>
            <button onClick={() => handleDropdownPageSelection('Manage Subjects')}>Manage Subjects</button>
            <button onClick={() => handleDropdownPageSelection('Manage Subtopics')}>Manage Subtopics</button>
            <button onClick={() => handleDropdownPageSelection('Manage Coupons')}>Manage Coupons</button>
            <button onClick={() => handleDropdownPageSelection('Edit Questions')}>Edit Questions</button>
            <button onClick={() => handleDropdownPageSelection('Manage Daily MCQ')}>Manage Daily MCQs</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPage;
