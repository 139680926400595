import React, { useState, useEffect, useMemo } from 'react'; // Removed useRef as it's not used
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCrown, FaUser, FaGift, FaWallet, FaHome } from 'react-icons/fa';

const MenuBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(null);

  const menuItems = useMemo(() => [
    { label: 'Home', path: '/', icon: <FaHome /> },
    { label: 'QBank', path: '/qbankpage', icon: <FaCrown /> },
    { label: 'Profile', path: '/profile', icon: <FaUser /> },
    { label: 'GTs', path: '/grandtests', icon: <FaGift /> },
    { label: 'Wallet', path: '/wallet', icon: <FaWallet /> },
  ], []); // memoize menuItems to prevent unnecessary recalculations

  // Set active item based on the current location (URL)
  useEffect(() => {
    const active = menuItems.findIndex(item => item.path === location.pathname);
    setActiveIndex(active);
  }, [location, menuItems]);

  return (
    <div style={styles.menuBar}>
      {menuItems.map((item, index) => (
        <div
          key={index}
          style={{
            ...styles.menuItem,
            borderBottom: activeIndex === index ? '2px solid skyblue' : 'none',
          }}
          onClick={() => {
            setActiveIndex(index); // Set active item on click
            navigate(item.path);
          }}
        >
          <div style={styles.icon}>{item.icon}</div>
          <div style={styles.label}>{item.label}</div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  menuBar: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: '#fff',
    height: '60px',
    zIndex: 1000,
    boxShadow: '0 -2px 6px rgba(0, 0, 0, 0.1)',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
    color: '#333',
    transition: 'color 0.3s',
  },
  icon: {
    fontSize: '20px',
  },
  label: {
    fontSize: '12px',
    marginTop: '5px',
  },
};

export default MenuBar;
